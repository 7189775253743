* {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
}

a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
}

.link-unstyled {
    color: inherit;
    text-decoration: none;
}



.link-unstyled:hover {
    color: inherit;
    text-decoration: none;
}


/* CSS CABEÇALHO Começo */


.container_pai {
    display: flex;
    flex-direction: row;
    background-color: #001969;
    height: 80px;
    color: white;
    justify-content: center;
    align-items: center;
}

.cont1,
.cont2,
.cont3,
.cont4,
.cont5 {
    display: flex;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
}


.cont1:hover,
.cont2:hover,
.cont3:hover,
.cont4:hover,
.cont5:hover {
    display: flex;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: large;
}

/* CSS CABEÇALHO Fim */

/* CSS CORPO Começo */

.pai {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 6vh;
    border-radius: 15px;
    box-shadow: 3px 3px 8px 3px rgba(165, 165, 165, 0.4);
}

.filha {
    width: 600px;
    margin: 10.0vh;
}

label {
    margin: 2px;
    font-size: large;
}



h1 {
    text-align: center;
}

#pai1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#pai2 {
    display: flex;
    flex-direction: column;
    margin-left: 45%;
    margin-right: 45%;
}

#filha1 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
    margin-bottom: 0.2rem;
}

#filha2 {
    text-align: center;
    display: flex;
    flex-direction: column;
}

p {
    margin: 5%;
    font-size: medium;
    text-align: center;
}


#filha2 label {
    font-size: 1.0rem;
    font-family: Impact, sans-serif;
}

#filha1 label {
    font-size: 1.0rem;
    font-family: Impact, sans-serif;
}




.divcima {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.divcima1 {
    text-align: center;
    align-items: center;
    padding: 5vh;
    margin-left: 80vh;
    margin-right: 80vh;
}

.divcima2 {
    text-align: center;
    align-items: center;
    padding: 4vh;
}

/* CSS CORPO Fim */



/* CSS BOTAO CAD */

.botaocad {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    padding: 2.00rem;
    border-radius: 50px;
}

.botaocad input {
    font-size: large;
    margin-top: 2.5px;
    background-color: rgb(16, 92, 255);
    padding: 0.65rem;
    border-radius: 5px;
    cursor: pointer;
}




/* CSS DROPDOWN */



.dropdown {
    position: relative;
    display: inline-block;
}


.dropdown-button {
    background-color: inherit;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.dropdown-button:hover {
    background-color: inherit;
    color: white;
    font-size: large;
    padding: 10px;
    border: none;
    cursor: pointer;
}


.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: none;
}

.dropdown-menu li {
    padding: 10px;
}

.dropdown-menu li:hover {
    color: black;
    font-size: large;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

/* CSS PÁGINA HOME Começo */

.home1 {
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;

}

.cadPatrimonio {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 25px;
    margin-bottom: 10px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 3px 3px 8px 3px rgba(165, 165, 165, 0.4);
}

.cadUsuario {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 10px;
    border-radius: 20px;
    box-shadow: 3px 3px 8px 3px rgba(165, 165, 165, 0.4);
    text-align: center;
}

.cadFiliais {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 10px;
    border-radius: 20px;
    box-shadow: 3px 3px 8px 3px rgba(165, 165, 165, 0.4);
    text-align: center;
}

.cadFuncionarios {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10px;
    margin-left: 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    box-shadow: 3px 3px 8px 3px rgba(165, 165, 165, 0.4);
    text-align: center;
}

.home2 {
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;
}


.monitorar {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 10px;
    border-radius: 20px;
    box-shadow: 3px 3px 8px 3px rgba(165, 165, 165, 0.4);
    text-align: center;
}

.btnHome {
    background-color: blue;
    color: #ffffff;
    padding: 8px;
    border-radius: 5px;
    border: none;
}

.btnHome:hover {
    background-color: #008000;
    color: #ffffff;
    padding: 8px;
    border-radius: 5px;
    border: none
}


/* LOGIN - TELA PRINCIPAL*/
.div_principal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    height: 100vh;

}

.div_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
}

.logo1 {

    width: 390px;
    height: 88px;
}

.div_logopai {
    margin-bottom: 90px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
}

.div_top {
    height: 8vh;
    width: 100%;
    background-color: #001969;
    align-self: top;
    margin: 0;
}

.div_bottom {
    height: 8vh;
    width: 100%;
    background-color: #001969;
    align-self: bottom;
    margin: 0;
}

.div_elementos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
}

.space {
    margin-top: 3px;
}

.divMovimento{
    display: flex;
    justify-content: Center;
    align-items: center;
}


table {
    width: 100%;
    border-collapse: collapse;
}

.tablemonitorar{
    margin: 0px;
    padding: 0px;
}



th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

tr:hover {
    background-color: #f5f5f5;
}



/* Estilo base para o elemento select */
select {
    margin: 25px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

/* Estilo quando o select está focado */
select:focus {
    outline: none;
    border-color: #2196F3;
    /* Cor de foco azul */
    box-shadow: 0 0 5px rgba(33, 150, 243, 0.7);
    /* Sombra de foco */
}

/* Estilo para seta personalizada (você pode usar uma imagem) */
select::after {
    content: '\25BC';
    /* Unicode para a seta para baixo ▼ */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

/* Estilo para o dropdown (opções) */
select option {
    padding: 10px;
    font-size: 16px;
    background-color: #fff;
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Estilo quando uma opção está selecionada */
select option:checked {
    background-color: #2196F3;
    /* Cor de seleção azul */
    color: white;
}

/* Estilo para o dropdown quando o select está focado */
select:focus option {
    background-color: #fff;
    /* Cor de fundo de foco */
    color: #333;
}

.center-select {
    display: flex;
    justify-content: center;
    /* Centraliza horizontalmente */
    align-items: center;
    /* Centraliza verticalmente (opcional) */
    height: 100vh;
    /* Altura total da viewport para centralizar verticalmente */
}

